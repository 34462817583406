import * as React from 'react';
import { useTheme } from 'styles';
import CarouselSection from 'components/CarouselSection';
import SaleCarousel from './SaleCarousel';
import Avatar from 'react-app/components//Avatar';
import Stack from '@mui/material/Stack';
import CardTitle from 'react-app/components/CardTitle';
import Link from 'components/Link';
import Label from 'react-app/components/Label';
import CardActionArea from 'components/CardActionArea';
import ShopIcon from '@mui/icons-material/ShoppingCart';
import { useDeferredUser } from 'context/user';
import retailShopIcon from 'images/logos/hw-retail-shop-icon.svg';
import wholesaleShopIcon from 'images/logos/hw-profi-shop-icon.svg';
import { red } from '@mui/material/colors';
import { appData } from 'config/data.cjs';

const cardSize = 64;

function SaleCard(props) {
	const {
		title,
		subtitle,
		shopLink: retailShopLink,
		profiShopLink,
		isOnSale,
		discount,
		imageSrcSet = {},
		noIcon,
		themeColorId = appData.pages[props.pageId]?.options?.themeColorId
	} = props;

	const theme = useTheme();
	const themeColor = theme.config[themeColorId];

	const [ {isRetail = true} ] = useDeferredUser();
	const shopLink = isRetail ? retailShopLink : profiShopLink;

	return (
		<CardActionArea
			href={shopLink}
			component={shopLink ? Link : undefined}
			sx={{
				color: '#000',
				borderRadius: cardSize
			}}
		>
			<Stack
				direction="row"
				spacing={1}
				maxWidth={300}
				borderRadius={cardSize}
				maxHeight={cardSize}
				backgroundColor="rgba(0, 0, 0, 0.3)"
				paddingRight={2}
				alignItems="center"
				color="#fff"
			>
				<Avatar
					width={cardSize}
					height={cardSize}
					src={imageSrcSet?.xs}
					sx={{'&&': {
						backgroundColor: 'rgba(255, 255, 255, 0.3)'
					}}}
				>
					{isOnSale && (
						<Label
							sx={{'&&': {
								position: 'absolute',
								backgroundColor: red[600],
								color: '#fff',
								fontWeight: 700,
								margin: 0,
								top: 0,
								left: 0,
								borderRadius: 0,
								width: '100%',
								paddingX: 1,
								paddingTop: .5,
								textAlign: 'center'
							}}}
						>
							{discount ? `-${discount}%` : 'SALE'}
						</Label>
					)}
				</Avatar>
				<CardTitle
					dense
					subtitle={subtitle}
					HeadingProps={{
						sx: {'&&': {
							...theme.mixins.lineClamp,
							WebkitLineClamp: 2,
							color: '#fff',
							fontSize: '14px'
						}}
					}}
					SubheadingProps={{
						sx: {'&&': {
							...theme.mixins.lineClamp,
							WebkitLineClamp: 1,
							color: 'rgba(255, 255, 255, 0.65)',
							fontSize: '12px'
						}}
					}}
				>
					{title}
				</CardTitle>
				{!noIcon && (
					<Stack
						padding={.75}
						borderRadius="50%"
						backgroundColor={themeColor || 'primary.dark'}
					>
						<ShopIcon/>
					</Stack>
				)}
			</Stack>
		</CardActionArea>
	);
}

function SaleSection(props) {
	const {
		pageId,
		cards: cardsProp = [],
		CarouselComponentProps,
		shopLink,
		...rest
	} = props;

	const [ {isRetail = true} ] = useDeferredUser();

	const cards = [
		...cardsProp,
		...(shopLink ? [{
			noIcon: true,
			title: `Weitere Artikel finden Sie in unserem ${isRetail ? 'Online' : 'Profi'}-Shop`,
			shopLink,
			profiShopLink: shopLink,
			imageSrcSet: {
				xs: isRetail ? retailShopIcon : wholesaleShopIcon
			}
		}] : [])
	];

	return (
		<CarouselSection
			{...rest}
			cards={cards}
			CarouselComponent={SaleCarousel}
			CarouselComponentProps={{
				...CarouselComponentProps,
				centered: true,
				CardComponent: SaleCard,
				cardSizes: {xs: 300},
				CardProps: {
					...CarouselComponentProps?.CardProps,
					pageId,
					size: 'small'
				}
			}}
		/>
	);
}

export default React.memo(SaleSection);
