/* global WEBPACK_DEV */

import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, useClsUtils } from 'styles';
import { formatNumber } from 'lib/helpers';
import Typography from '@mui/material/Typography';
import Label from 'react-app/components/Label';
import { fieldDefs, contacts } from 'config/sale.cjs';
import Parser from 'react-app/components/HtmlParser';
import IconButton from '@mui/material/IconButton';
import ShopIcon from '@mui/icons-material/ShoppingCart';
import ShareIcon from '@mui/icons-material/Share';
import InfoIcon from '@mui/icons-material/Info';
import MailIcon from '@mui/icons-material/MailOutline';
import Button from 'components/Button';
import Link from 'components/Link';
import ShippingForm from 'components/ShippingForm';
import useWebShare from 'react-app/hooks/useWebShare';
import { useMemoCallback } from 'react-app/hooks';
import SaleDisclaimer from './SaleDisclaimer';
import ProductCard, {
	Price,
	Property,
	formatNumberOpts,
	cardSizes
} from 'components/ProductCard';
import { useDeferredUser } from 'context/user';

export const DIALOG_INFO_VIEW = 'info';
export const DIALOG_SHIPPING_VIEW = 'shipping';

export {
	cardSizes
};

const componentId = 'ProductShipping';

const useClasses = createClasses((theme, {size}) => {
	return {
		root: {},
		priceContent: {
			width: '100%',
			display: 'flex',
			flexWrap: 'wrap',
			marginTop: 'auto',
			alignItems: 'center',
			justifyContent: 'flex-end',
			'& > .MuiLabel-root': {
				marginRight: 'auto'
			}
		},
		priceContainer: {
			lineHeight: 1.25,
			fontSize: theme.typography.pxToRem(16),
			display: 'flex',
			flexBasis: '100%',
			flexWrap: 'wrap',
			gap: theme.spacing(1),
			justifyContent: 'space-between',
			fontWeight: 700,
			whiteSpace: 'nowrap'
		},
		price: {
			display: 'inline-block',
			marginLeft: 'auto',
			'&::after': {
				position: 'relative',
				top: -5,
				fontSize: theme.typography.pxToRem(12),
				content: '"*"'
			},
			...(size === 'small' && {
				marginBottom: 0
			}),
			...(size === 'large' && {
				fontSize: theme.typography.pxToRem(18)
			})
		},
		suggestedPrice: {
			textAlign: 'right',
			textDecorationColor: theme.vars.palette.error.main,
			whiteSpace: 'nowrap'
		},
		suggestedPriceContainer: {
			whiteSpace: 'nowrap'
		},
		priceRange: {
			'&::before': {
				fontSize: theme.typography.pxToRem(14),
				content: '"ab "'
			}
		},
		label: {
			alignSelf: 'center',
			marginLeft: 0,
			marginRight: 0,
			...(size === 'small' && {
				...theme.mixins.textOverflow,
				fontSize: theme.typography.pxToRem(10)
			})
		},
		content: {
			marginBottom: theme.spacing(1.5),
			'& > p': {
				marginBottom: theme.spacing(1.5),
				'&:first-child': {
					marginTop: 0
				}
			}
		},
		caption: {
			textAlign: 'right',
			lineHeight: 1.2,
			marginBottom: theme.spacing(.5)
		},
		dialogContent: {
			'& .SaleCard-.retailPrice': {
				fontSize: theme.typography.pxToRem(20)
			}
		},
		dialogInnerContent: {},
		dialogFooterContent: {
			paddingTop: 0,
			marginTop: 'auto'
		},
		shippingFormTitle: {
			paddingLeft: theme.spacing(2.5),
			paddingRight: theme.spacing(2.5)
		}
	};
}, {
	name: 'SaleCard'
});

function SaleCard(props) {
	const {
		classes: classesProp,
		className,
		id,
		title,
		subtitle,
		description,
		descriptionHtml,
		condition,
		itemNo,
		length,
		width,
		height,
		thickness,
		retailPrice,
		wholeSalePrice,
		suggestedPrice,
		isPriceRange,
		discount,
		unit,
		inStock,
		isPublic,
		isOnSale,
		isDiscontinued,
		isSample,
		isPickup,
		shopLink: retailShopLink,
		profiShopLink,
		onDialogOpen,
		onDialogClose,
		imageSrcSet = {},
		defaultDialogView = DIALOG_INFO_VIEW,
		iconButtonMode,
		shareEnabled,
		buttonSize = 'medium',
		filesData,
		category,
		...rest
	} = props;

	const { cxCls } = useClsUtils();
	const classes = useClasses(props);
	const [ {isRetail = true, isWholesale = false} ] = useDeferredUser();

	const { share, isSupported: shareSupported } = useWebShare();
	const [ infoDialogOpen, setInfotDialogOpen ] = React.useState(false);
	const [ dialogView, setDialogView ] = React.useState(defaultDialogView);

	const isGiftItem = category === 'geschenkartikel';
	const isSmall = props.size === 'small';
	const stockUnit = fieldDefs.inStock.suffix(inStock, props);
	const shopLink = isRetail ? retailShopLink : profiShopLink;
	const shopName = isRetail ? 'Online-Shop' : 'Profi-Shop';
	const infoView = dialogView === DIALOG_INFO_VIEW;

	const handleInfoDialogOpen = useMemoCallback((...args) => {
		setDialogView(DIALOG_INFO_VIEW);
		setInfotDialogOpen(true);
		if (typeof onDialogOpen === 'function') {
			onDialogOpen(...args);
		}
	});

	const handleInfoDialogClose = useMemoCallback((...args) => {
		const [, reason] = args;
		if (!WEBPACK_DEV && (reason === 'backdropClick' || reason === 'escapeKeyDown') && dialogView === DIALOG_SHIPPING_VIEW) {
			return;
		}
		setInfotDialogOpen(false);
		if (typeof onDialogClose === 'function') {
			onDialogClose(...args);
		}
	});

	const handleShippingDialogOpen = useMemoCallback((...args) => {
		setDialogView(DIALOG_SHIPPING_VIEW);
		setInfotDialogOpen(true);
		if (typeof onDialogOpen === 'function') {
			onDialogOpen(...args);
		}
	});

	const handleShareClick = useMemoCallback(() => {
		if (shareSupported && 'share' in navigator) {
			share({
				title,
				text: subtitle,
				url: shopLink
			});
		}
	});

	const handleChangeViewClick = useMemoCallback(() => {
		setDialogView(dialogView === DIALOG_INFO_VIEW ? DIALOG_SHIPPING_VIEW : DIALOG_INFO_VIEW);
	});

	const infoIcon = React.useMemo(() => (
		<IconButton
			key="info"
			title="mehr Infos"
			color="inherit"
			onClick={handleInfoDialogOpen}
		>
			<InfoIcon fontSize="small"/>
		</IconButton>
	), [handleInfoDialogOpen]);

	const shareIcon = React.useMemo(() => (
		shopLink && shareSupported && shareEnabled ? (
			<IconButton
				key="share"
				title="Teilen"
				color="inherit"
				onClick={handleShareClick}
			>
				<ShareIcon fontSize="small"/>
			</IconButton>
		) : null
	), [shopLink, shareSupported, shareEnabled, handleShareClick]);

	const shopButton = React.useMemo(() => (
		shopLink ? iconButtonMode ? (
			<IconButton
				key="shop"
				color="primary"
				to={shopLink}
				component={Link}
			>
				<ShopIcon fontSize="small"/>
			</IconButton>
		) : (
			<Button
				key="shop"
				color="primary"
				to={shopLink}
				startIcon={<ShopIcon/>}
			>
				zum Shop
			</Button>
		) : null
	), [shopLink, iconButtonMode]);

	const shippingButton = React.useMemo(() => (
		iconButtonMode ? (
			<IconButton
				key="more"
				color="primary"
				onClick={handleShippingDialogOpen}
			>
				<MailIcon fontSize="small"/>
			</IconButton>
		) : (
			<Button
				key="more"
				color="primary"
				size={buttonSize}
				onClick={handleShippingDialogOpen}
				startIcon={<MailIcon/>}
			>
				anfragen
			</Button>
		)
	), [handleShippingDialogOpen, iconButtonMode, buttonSize]);

	const CardActionAreaProps = React.useMemo(() => ({
		...(shopLink ? {
			to: shopLink,
			component: Link
		} : {
			onClick: handleInfoDialogOpen
		})
	}), [handleInfoDialogOpen, shopLink]);

	const CardActionsProps = React.useMemo(() => ({
		dense: true,
		buttons: shopLink ? shopButton : shippingButton,
		icons: (
			<>
				{infoIcon}
				{shareIcon}
			</>
		)
	}), [shopButton, shippingButton, infoIcon, shareIcon, shopLink]);

	const DialogProps = React.useMemo(() => ({
		onClose: handleInfoDialogClose,
		alignActions: 'start',
		buttons: (
			shopLink && infoView ? (
				<Button
					color="primary"
					to={shopLink}
					startIcon={<ShopIcon/>}
				>
					zum Shop
				</Button>
			) : (
				<Button
					key={infoView ? 'infoView' : 'shippingView'}
					color={infoView ? 'primary' : 'default'}
					onClick={handleChangeViewClick}
					startIcon={infoView ? <MailIcon/> : <InfoIcon/>}
				>
					{infoView ? 'anfragen' : 'Mehr Infos'}
				</Button>
			)
		),
		icons: infoView ? shareIcon : null,
		className: cxCls(
			isOnSale && classes.soldOut
		)
	}), [
		handleInfoDialogClose,
		shopLink,
		shareIcon,
		isOnSale,
		classes.soldOut,
		handleChangeViewClick,
		infoView,
		cxCls
	]);

	const ProductCardClasses = React.useMemo(() => ({
		small: classes.small,
		large: classes.large,
		cardMedia: classes.cardMedia,
		dialogMedia: classes.dialogMedia,
		dialogContent: classes.dialogContent
	}), [classes]);

	const hasDiscount = discount && discount >= 2;
	const price = (isDiscontinued || isOnSale || isGiftItem) && !shopLink && (isRetail ? retailPrice : wholeSalePrice || retailPrice ? (retailPrice / 1.19) : null);

	const priceContent = (!isPublic || price || unit || inStock || suggestedPrice || hasDiscount) && (
		<div className={classes.priceContent}>
			{price && (unit || suggestedPrice) && (
				<Typography variant="caption" className={classes.suggestedPriceContainer}>
					{!shopLink && (
						<>
							{unit && <>Preis/{unit}</>}
							{isRetail && suggestedPrice && suggestedPrice > price && (
								<>
									{unit && ' | UVP '}
									<span className={classes.suggestedPrice}>
										<Price value={suggestedPrice}/>
									</span>
								</>
							)}
						</>
					)}
				</Typography>
			)}
			{(!isPublic || price || inStock || hasDiscount) && (
				<Typography
					className={classes.priceContainer}
					{...(!shopLink && price && {
						flexDirection: 'row-reverse'
					})}
					component="span"
					color="primary"
				>
					{!shopLink && price && (
						<span
							className={cxCls(
								classes.price,
								isPriceRange && classes.priceRange
							)}
						>
							<Price value={price}/>
						</span>
					)}
					{!isPublic && (
						<Label className={classes.label} color="error">
							nicht öffentlich
						</Label>
					)}
					{isPublic && hasDiscount ? (
						<Label className={classes.label} color="error">
							{`${discount}% ${isPickup && isWholesale ? 'Abholrabatt' : 'Rabatt'}`}
						</Label>
					) : null}
					{isPublic && inStock && (!isSmall || !hasDiscount) ? (
						<Label className={classes.label} color="info">
							{`${formatNumber(Math.floor(inStock), null)} ${stockUnit && stockUnit !== 'Stück' ? stockUnit : ''}`} auf Lager
						</Label>
					) : null}

				</Typography>
			)}
		</div>
	);

	const selectedItems = [{
		id,
		itemNo,
		title,
		subtitle,
		retailPrice,
		wholeSalePrice,
		unit,
		imageSrc: imageSrcSet?.xs
	}];

	const labelContent =  isOnSale ? 'Aktionsartikel' : isDiscontinued ? 'Auslaufartikel' : isSample ? 'Muster' : isPickup ? 'Selbstabholung' :  '';
	const labelColor =  isOnSale ? 'error' : isDiscontinued ? 'info' : isSample || isPickup ? 'default' : null;

	const cardContent = (
		<>
			{description && (
				<Parser className={classes.content} html={descriptionHtml}/>
			)}

			{itemNo && itemNo !== id && (
				<Property title="Artikel-Nr.:">
					{itemNo}
				</Property>
			)}

			{(length || width || thickness) && (
				<Property title="Maße">
					{length ? <div>{`Länge: ${formatNumber(length, null, formatNumberOpts)} mm`}</div> : null}
					{width ? <div>{`Breite: ${formatNumber(width, null, formatNumberOpts)} mm`}</div> : null}
					{height ? <div>{`Höhe: ${formatNumber(height, null, formatNumberOpts)} mm`}</div> : null}
					{thickness ? <div>{`Stärke: ${formatNumber(thickness, null, formatNumberOpts)} mm`}</div> : null}
				</Property>
			)}

			{priceContent}
		</>
	);

	const dialogContent = (
		<>
			{infoView ? (
				<>
					<div className={classes.dialogInnerContent}>
						{cardContent}
					</div>
					<div className={cxCls(
						classes.dialogFooterContent,
						classes.dialogInnerContent
					)}>
						<SaleDisclaimer/>
					</div>
				</>
			) : (
				<ShippingForm
					selectedItems={selectedItems}
					componentId={`${componentId}Form_${id}`}
				/>
			)}
		</>
	);

	return (
		<ProductCard
			key={id}
			dialogOpen={infoDialogOpen}
			{...rest}
			className={cxCls(
				classes.root,
				isOnSale && classes.soldOut,
				className
			)}
			classes={ProductCardClasses}
			title={title}
			dialogTitle={infoView ? title : 'Produkt anfragen'}
			subtitle={infoView ? subtitle : null}
			shortContent={priceContent}
			dialogContent={dialogContent}
			showDialogMedia={infoView}
			labelContent={labelContent}
			labelColor={labelColor}
			CardActionAreaProps={CardActionAreaProps}
			CardActionsProps={isSmall ? null : CardActionsProps}
			DialogProps={DialogProps}
			imageSrcSet={imageSrcSet}
			filesData={filesData}
			carouselMode
		>
			{cardContent}
		</ProductCard>
	);
}

SaleCard.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	itemNo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	length: PropTypes.number,
	width: PropTypes.number,
	height: PropTypes.number,
	thickness: PropTypes.number,
	inStock: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	retailPrice: PropTypes.number,
	wholeSalePrice: PropTypes.number,
	suggestedPrice: PropTypes.number,
	isPriceRange: PropTypes.bool,
	unit: PropTypes.string,
	contact: PropTypes.array,
	shopLink: PropTypes.string,
	profiShopLink: PropTypes.string,
	iconButtonMode: PropTypes.bool,
	shareEnabled: PropTypes.bool,
	defaultDialogView: PropTypes.oneOf([DIALOG_INFO_VIEW, DIALOG_SHIPPING_VIEW])
};

export default React.memo(SaleCard);
