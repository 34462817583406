import Divider from '@mui/material/Divider';
import Disclaimer from 'components/Disclaimer';
import { useDeferredUser } from 'context/user';
import { disclaimer } from 'config/sale.cjs';

export default function SaleDisclaimer(props) {
	const [ {userType = 'retail'} ] = useDeferredUser();
	return (
		<>
			<Divider
				flexItem
				sx={(theme) => ({
					marginTop: theme.config.containerSpacing,
					marginBottom:  theme.config.gutterSpacing
				})}
			/>
			<Disclaimer {...props}>
				{disclaimer[userType]}
			</Disclaimer>
		</>
	);
}
